<template>
  <div class="module-price">
    <div class="module-group">
      <div class="group-cont">
        <div class="flex flex-y-center">
          <span class="m-r-5">统一调整基价:</span>
          <el-input style="width: 231px" size="mini">
            <span slot="suffix">元/kg</span>
          </el-input>
        </div>
        <el-button class="m-l-5" type="primary" size="mini">统一调整基价</el-button>
        <span class="m-l-5 c-gray-light">(根据全局数据统一调整)</span>
      </div>
      <div class="group-cont">
        <div class="flex flex-y-center">
          <span class="m-r-5">统一调整售价:</span>
          <el-select style="width: 113px" size="mini" v-model="adjustVal">
            <el-option
              v-for="(item, index) in adjustList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
          <el-input class="m-l-5" style="width: 113px" size="mini">
            <span v-if="Number(adjustVal) < 3" slot="suffix">元/kg</span>
            <span v-else slot="suffix">%</span>
          </el-input>
        </div>
        <el-button class="m-l-5" type="primary" size="mini">统一调整售价</el-button>
        <span class="m-l-5 c-gray-light">(根据全局数据统一调整)</span>
      </div>
    </div>
    <div class="module-group">
      <vxe-grid v-bind="tableOptions" :data="goodsGroupList">
        <template #default_sub_operate="{ rowIndex }">
          <el-link type="success" @click="goodsGroupEdit(rowIndex)">添加选中商品</el-link>
          <el-link class="m-l-5" type="primary" @click="goodsGroupEdit(rowIndex)">
            维护商品组
          </el-link>
        </template>
        <template #default_main_operate="{ rowIndex }">
          <el-link type="success" size="mini" plain @click="goodsGroupAdd"> 新增商品组 </el-link>
          <el-link class="m-l-5" type="danger" @click="goodsGroupDelete(rowIndex)">
            删除商品组
          </el-link>
        </template>
      </vxe-grid>
      <div class="group-cont" style="margin-top: -1px">
        <div class="flex flex-y-center">
          <span class="m-r-5">局部调整售价:</span>
          <el-select style="width: 113px" size="mini" v-model="adjustVal">
            <el-option
              v-for="(item, index) in adjustList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
          <el-input class="m-l-5" style="width: 113px" size="mini">
            <span v-if="Number(adjustVal) < 3" slot="suffix">元/kg</span>
            <span v-else slot="suffix">%</span>
          </el-input>
        </div>
        <el-button class="m-l-5" type="primary" size="mini">局部调整售价</el-button>
        <span class="m-l-5 c-gray-light">(根据选中商品局部调整)</span>
      </div>
    </div>

    <PopupBatchSetPrice
      v-if="isShowPopup"
      :show.sync="isShowPopup"
      :obj="obj"
      :adjustList="adjustList"
      @success="importConfirm"
    />
  </div>
</template>

<script>
import PopupBatchSetPrice from './popup-batch-set-price'
export default {
  components: {
    PopupBatchSetPrice
  },
  data() {
    return {
      adjustVal: '1',
      adjustList: [
        {
          key: '上调数值',
          val: '1',
          prompt: '元/kg'
        },
        {
          key: '下调数值',
          val: '2',
          prompt: '元/kg'
        },
        {
          key: '上调比例',
          val: '3',
          promot: '%'
        },
        {
          key: '下调比例',
          val: '4',
          promot: '%'
        }
      ],
      goodsGroupList: [
        {
          id: '1',
          name: '外径加价',
          count: 24
        },
        {
          id: '2',
          name: '厚度加价',
          count: 33
        }
      ],
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          { type: 'checkbox', width: 26 },
          { title: '商品组名称', field: 'name' },
          { title: '商品数量', width: 201, field: 'count' },
          {
            title: '商品操作',
            width: 201,
            align: 'right',
            slots: { default: 'default_sub_operate' }
          },
          {
            title: '商品组操作',
            width: 201,
            align: 'right',
            slots: { default: 'default_main_operate' }
          }
        ]
      },
      isShowPopup: false
    }
  },
  methods: {
    // 商品组-新增
    goodsGroupAdd() {
      this.isShowPopup = true
    },
    // 商品组-编辑
    goodsGroupEdit() {
      this.isShowPopup = true
    },
    // 商品组-删除
    goodsGroupDelete() {}
  }
}
</script>

<style lang="less" scoped>
.module-price {
  .module-group {
    & ~ .module-group {
      margin-top: 5px;
    }
    .group-cont {
      display: flex;
      align-items: center;
      height: 28px;
      line-height: 28px;
      padding: 5px;
      border: 1px solid #e4e7ed;
      white-space: nowrap;
      & ~ .group-cont {
        margin-top: -1px;
      }
    }
  }
}
</style>
