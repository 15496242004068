<template>
  <div class="box-dialog">
    <el-dialog
      width="600px"
      title="添加编辑加价商品"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form ref="refForm" label-width="120px">
        <el-form-item label="加价商品组名称:">
          <el-input v-model="categoryActiveName" size="mini" />
        </el-form-item>
        <el-form-item label="统一调整售价:">
          <el-select size="mini" style="width: 113px" v-model="adjustVal">
            <el-option
              v-for="(item, index) in adjustList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
          <el-input class="m-l-5" style="width: 113px" size="mini">
            <span v-if="Number(adjustVal) < 3" slot="suffix">元/kg</span>
            <span v-else slot="suffix">%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="已选列表数据:">
          <div>
            <span class="c-blue">10</span>
            <span class="m-l-5">条</span>
          </div>
          <vxe-grid v-bind="tableOptions" ref="refTable" :data="tableList" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    obj: {
      type: Object,
      required: true
    },
    adjustList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      categoryActiveName: '',
      categoryImportId: '',
      categoryList: [],
      tableOptions: {
        ref: 'refTable',
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          {
            type: 'checkbox',
            width: 40
          },
          {
            title: '材质名称',
            field: 'cai_zhi'
          },
          { title: '密度', field: 'val' }
        ]
      },
      tableList: [],
      adjustVal: ''
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    categoryImportId(val) {
      if (val) {
        this.getCaizhiList(val)
      }
    }
  },
  mounted() {
    this.selfShow = this.show
    this.categoryActiveName = this.obj.name
    this.getCategoryList()
  },
  methods: {
    // 获取同级类目列表
    getCategoryList() {
      this.$api({
        methods: 'get',
        url: '/admin/gcvip/columnData/list',
        params: {
          id: this.obj.parent_id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let result = res.data.list.filter((item) => {
              return item.id !== this.obj.id && !item.has_children
            })
            this.categoryList = result.map((item) => {
              return {
                key: item.name,
                val: item.id
              }
            })
            if (!this.categoryImportId && this.categoryList.length) {
              this.categoryImportId = this.categoryList[0].val
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },
    // 获取引用材质列表
    getCaizhiList(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/midu/list',
        params: {
          id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.tableList = res.data.list
          this.$nextTick(() => {
            this.$refs.refTable.setAllCheckboxRow(true)
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      let result = this.$refs.refTable.getCheckboxRecords()
      this.$emit('success', result)
      this.popupCancel()
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
